import { defineStore } from 'pinia';
import { store } from '@/store';

export const useMenuStore = defineStore('menu', {
  state: () => ({
    selectedProvince: localStorage.getItem('selectedProvince'),
    selectedCityAdcode: localStorage.getItem('selectedCityAdcode'),
    selectedCityCenter: JSON.parse(localStorage.getItem('selectedCityCenter')),
    selectedMonth: localStorage.getItem('selectedMonth'),
    sourceType: localStorage.getItem('sourceType'),
    selectedMall: {
      poi_id: '',
      mall_id: '',
      name: '',
      address: '',
      center_gcj: '',
      adcode: '',
      building_area: 0,
      zone: '',
    },
    mallType: '1',
    dataType: '0',
    regionObjs: [],
    mallObjs: [],
    dateInfo: '',
    regionInfo: '',
  }),
  getters: {},
  actions: {
    async setSelectedProvince(province: string) {
      this.selectedProvince = province;
      localStorage.setItem('selectedProvince', province);
    },
    async setSelectedCityAdcode(adcode: string) {
      this.selectedCityAdcode = adcode;
      localStorage.setItem('selectedCityAdcode', adcode);
    },
    async setselectedCityCenter(location: { lat: string; lng: string }) {
      this.selectedCityCenter = location;
      localStorage.setItem('selectedCityCenter', JSON.stringify(location));
    },
    async setSelectedMall(mall) {
      this.selectedMall = mall;
      localStorage.setItem('selectedMall', JSON.stringify(mall));
    },
    async setSelectedMonth(date: string) {
      this.selectedMonth = date;
      localStorage.setItem('selectedMonth', date);
    },
    async setSourceType(type: string) {
      this.sourceType = type;
      localStorage.setItem('sourceType', type);
    },
    async setDataType(type: string) {
      this.dataType = type;
    },
    async setMallType(type: string) {
      this.mallType = type;
    },

    // 待定
    async datePickerInfo(date: any) {
      this.dateInfo = date;

      localStorage.setItem('dateInfo', date);
    },
    async chooseRegionInfo(value: any) {
      this.regionInfo = value;
      localStorage.setItem('regionInfo', value);
    },

    async setRegionObjs(data: any) {
      this.regionObjs = data;
    },
    async setMallObjs(data: any) {
      this.mallObjs = data;
    },
  },
});

export function getMenuStore() {
  return useMenuStore(store);
}
