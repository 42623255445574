<template>
  <div :class="[layoutCls, isFirstDay ? 'top' : '']">
    <marquee-text v-if="isFirstDay" :duration="15" :repeat="1">
      尊敬的用户，商场通上个月的数据预计将于本月1日下午产出，给您造成的不便敬请谅解！
    </marquee-text>
    <t-head-menu :class="[menuCls, isFirstDay ? 'hastop' : '']" :theme="theme" expand-type="popup" :value="active">
      <template #logo>
        <div v-if="showLogo" class="header-logo-container" @click="handleNav('/dashboard/base')">
          <tLogoFull class="t-logo" />
          <!-- <img class="t-logo" src="@/assets/home/logo.png" /> -->
        </div>
        <div v-else class="header-operate-left">
          <t-button theme="default" shape="square" variant="text" @click="changeCollapsed">
            <t-icon class="collapsed-icon" name="view-list" />
          </t-button>
          <search :layout="layout" />
        </div>
      </template>
      <MenuContent v-show="layout !== 'side'" class="header-menu" :nav-data="menu" />
      <template #operations>
        <div class="top-menu-link">
          <t-button ghost variant="text" @click="navToCube">数据魔方</t-button>
        </div>
        <div class="operations-container">
          <LoginDropdown @handle-exit="handleLogout" />
        </div>
      </template>
    </t-head-menu>
    <span class="btn-close" @click="handleClose">X</span>
  </div>
</template>

<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router';
import { PropType, computed, ref } from 'vue';
import Cookies from 'js-cookie';
import MarqueeText from 'vue-marquee-text-component';
import dayjs from 'dayjs';
import { useSettingStore, useUserStore } from '@/store';
import { prefix } from '@/config/global';
import tLogoFull from '@/assets/home/logo.svg?component';
import { MenuRoute } from '@/interface';
import LoginDropdown from '@/components/LoginDropdown/index.vue';
// import Notice from './Notice.vue';
import Search from './Search.vue';
import MenuContent from './MenuContent';

const userStore = useUserStore();

const isFirstDay = ref(dayjs().format('YYYY-MM-DD').split('-')[2] === '01');

const props = defineProps({
  theme: {
    type: String,
    default: '',
  },
  layout: {
    type: String,
    default: 'top',
  },
  showLogo: {
    type: Boolean,
    default: true,
  },
  menu: {
    type: Array as PropType<MenuRoute[]>,
    default: () => [],
  },
  isFixed: {
    type: Boolean,
    default: false,
  },
  isCompact: {
    type: Boolean,
    default: false,
  },
  maxLevel: {
    type: Number,
    default: 3,
  },
});

const router = useRouter();
const settingStore = useSettingStore();
const route = useRoute();
// const toggleSettingPanel = () => {
//   settingStore.updateConfig({
//     showSettingPanel: true,
//   });
// };

const active = computed(() => {
  const { layout } = props;
  if (!route.path) {
    return '';
  }
  return route.path
    .split('/')
    .filter((item, index) => index < props.maxLevel - (layout === 'mix' ? 1 : -1) && index > 0)
    .map((item) => `/${item}`)
    .join('');
});

const layoutCls = computed(() => [`${prefix}-header-layout`]);

const menuCls = computed(() => {
  const { isFixed, layout, isCompact } = props;
  return [
    {
      [`${prefix}-header-menu`]: !isFixed,
      [`${prefix}-header-menu-fixed`]: isFixed,
      [`${prefix}-header-menu-fixed-side`]: layout === 'side' && isFixed,
      [`${prefix}-header-menu-fixed-side-compact`]: layout === 'side' && isFixed && isCompact,
    },
  ];
});

const changeCollapsed = () => {
  settingStore.updateConfig({
    isSidebarCompact: !settingStore.isSidebarCompact,
  });
};

const handleNav = (url) => {
  router.push(url);
};
const handleClose = () => {
  isFirstDay.value = false;
};

const handleLogout = () => {
  userStore.setLoginStatus(0);
  // Cookies.set('login_type', '');
  // Cookies.remove('login_type', {
  //   domain: 'qq.com',
  // });
  const cookies = [
    'lbs_sid',
    'lbs_test_sid',
    'login_level',
    'head_img',
    'nick_name',
    'login_type',
    'sess_skey',
    'lbs_redirect',
    'lbs_refer',
  ];
  cookies.forEach((key) => {
    Cookies.remove(key, { domain: 'qq.com' });
  });

  router.push('/home');
};
// const navToGitHub = () => {
//   window.open('https://github.com/tencent/tdesign-vue-next-starter');
// };

const navToCube = () => {
  window.open('https://cube.lbs.qq.com/outlet_selection');
};
</script>
<style lang="less">
@import '@/style/variables.less';
.@{prefix}-header {
  &-layout {
    height: 64px;
  }

  &-menu-fixed {
    position: fixed;
    top: 0;
    z-index: 10;

    &-side {
      left: 232px;
      right: 0;
      z-index: 10;
      width: auto;
      transition: all 0.3s;

      &-compact {
        left: 64px;
      }
    }
  }

  &-logo-container {
    cursor: pointer;
    display: inline-flex;
    height: 64px;
  }
}
.header-menu {
  flex: 1 1 1;
  display: inline-flex;

  .t-menu__item {
    width: 104px;
    height: 40px;
    min-width: unset;
    color: rgba(255, 255, 255, 0.8);
    padding: 0px 16px;
  }
}

.operations-container {
  display: flex;
  align-items: center;
  margin-right: 12px;

  .t-popup__reference {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .t-button {
    margin: 0 8px;
    &.header-user-btn {
      margin: 0;
    }
  }

  .t-icon {
    font-size: 20px;
    &.general {
      margin-right: 16px;
    }
  }
}

.header-operate-left {
  display: flex;
  margin-left: 20px;
  align-items: normal;
  line-height: 0;

  .collapsed-icon {
    font-size: 20px;
  }
}

.header-logo-container {
  width: 160px;
  height: 27px;
  margin-left: 24px;
  color: @text-color-primary;

  img,
  .t-logo {
    width: 160px;

    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.header-user-account {
  display: inline-flex;
  align-items: center;
  color: @text-color-primary;
  .t-icon {
    margin-left: 4px;
    font-size: 16px;
  }
}

.t-head-menu__inner {
  height: 64px;
  border-bottom: 1px solid @border-level-1-color;
}

.t-menu--light {
  .header-user-account {
    color: @text-color-primary;
  }
}
.t-menu--dark {
  &.t-head-menu {
    background: #232a3a;
    .t-menu__logo:not(:empty) {
      margin-right: 80px;
    }
  }
  .t-head-menu__inner {
    height: 64px;
    // border-bottom: 1px solid var(--td-gray-color-10);
    border-bottom: 1px solid #232a3a;
  }
  .header-user-account {
    color: rgba(255, 255, 255, 0.55);
  }
  .t-button {
    --ripple-color: var(--td-gray-color-10) !important;
    &:hover {
      background: var(--td-gray-color-12) !important;
    }
  }
}

.operations-dropdown-container-item {
  width: 100%;
  display: flex;
  align-items: center;

  .t-icon {
    margin-right: 8px;
  }

  .t-dropdown__item {
    .t-dropdown__item__content {
      display: flex;
      justify-content: center;
    }
    .t-dropdown__item__content__text {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }

  .t-dropdown__item {
    width: 100%;
    margin-bottom: 0px;
  }
  &:last-child {
    .t-dropdown__item {
      margin-bottom: 8px;
    }
  }
}

.top-menu-link {
  margin-right: 10px;
  color: rgba(255, 255, 255, 0.8);
  .t-button {
    color: rgba(255, 255, 255, 0.8);
  }

  .t-button:hover {
    color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, 0.55);
    border-color: transparent !important;
  }
}
.hastop {
  margin-top: 20px !important;
}
.marquee-text-wrap {
  background-color: #fcefd8;
  color: #979697;
}
.marquee-text-content {
  width: 100% !important;
}
.marquee-text-text {
  width: 100%;
  text-align: right;
}
.btn-close {
  position: fixed;
  cursor: pointer;
  right: 20px;
  top: 0;
}
</style>
