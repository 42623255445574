<template>
  <router-view :class="[mode]" />
</template>
<script setup lang="ts">
import { computed, onMounted, watch, getCurrentInstance } from 'vue';
import { storeToRefs } from 'pinia';
import dayjs from 'dayjs';
import config from '@/config/style';
import { useSettingStore, useUserStore } from '@/store';
import { addLoginLog } from '@/services/loginApi';

const { appContext } = getCurrentInstance();
const beaconAction = appContext?.config.globalProperties.$beaconAction;
const userStore = useUserStore();
const store = useSettingStore();
const { loginStatus } = storeToRefs(userStore);
const mode = computed(() => {
  return store.displayMode;
});

onMounted(() => {
  store.updateConfig({ ...config });
});

// 登陆监控
watch(
  () => loginStatus.value,
  (val) => {
    const time = dayjs().format('YYYY-MM-DD');
    const firstLoginTime = localStorage.getItem('firstLoginTime') || '';
    // 登陆
    if (val === 1 && !firstLoginTime) {
      const userInfo = JSON.parse(localStorage.getItem('user'));
      const parmas = {
        user_id: userInfo.dataValues.user_id,
        user_name: userInfo.dataValues.user_name,
        date_time: dayjs().format('YYYY-MM-DD'),
        date_value: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        services_id: userInfo.dataValues.services_id,
      };
      // 上报服务
      beaconAction.onUserAction('login_success', {
        user_id: userInfo.dataValues.user_id,
      });
      addLoginLog(parmas);
      console.log('调用登陆日志服务', userInfo);
      localStorage.setItem('firstLoginTime', time);
    }
    // 退出登陆了
    if (val === 0) {
      localStorage.removeItem('firstLoginTime');
    }
  },
);
</script>
<style lang="less">
@import '@/style/variables.less';

#nprogress .bar {
  background: @brand-color !important;
}
</style>
