import Layout from '@/layouts';

export default [
  {
    path: '/manage',
    component: Layout,
    redirect: '/manage/details',
    name: 'manage',
    meta: { title: '我的后台', hidden: true },
    hidden: true,
    children: [
      {
        path: 'details',
        name: 'ManageDetails',
        component: () => import('@/pages/manage/details/index.vue'),
        meta: { title: '账户详情', icon: 'view-module' },
      },
      {
        path: 'user',
        name: 'ManageUser',
        component: () => import('@/pages/manage/user/index.vue'),
        meta: { title: '用户管理', icon: 'user' },
      },
      {
        path: 'service',
        name: 'ManageService',
        component: () => import('@/pages/manage/service/index.vue'),
        meta: { title: '企业服务管理', icon: 'tools' },
      },
      {
        path: 'log',
        name: 'UserLog',
        component: () => import('@/pages/manage/log/index.vue'),
        meta: { title: '用户日志管理', icon: 'user-circle' },
      },
    ],
  },
];
