<template>
  <div class="login-container">
    <t-dropdown :min-column-width="120" trigger="click" placement="bottom-right">
      <a class="user-menu">
        <span class="header-img">
          <t-avatar v-if="image" size="30" :image="image" :hide-on-load-failed="false" />
          <t-avatar v-else size="30"><UserIcon size="24" /></t-avatar>
          <span class="nickname">{{ nickName }}</span>
          <ChevronDownIcon size="16" />
        </span>
      </a>
      <template #dropdown>
        <t-dropdown-menu>
          <t-dropdown-item v-for="(item, index) in options" :key="index" @click="item.onClick">
            <div class="menu-icon"><component :is="item.icon" />{{ item.content }}</div>
          </t-dropdown-item>
        </t-dropdown-menu>
      </template>
    </t-dropdown>
  </div>
</template>
<script setup lang="ts">
import Cookies from 'js-cookie';
import { useRoute, useRouter } from 'vue-router';
import { onMounted, ref, watch } from 'vue';
import { ChevronDownIcon, UserIcon } from 'tdesign-icons-vue-next';
import SettingIcon from '@/assets/icons/setting-icon.svg?component';
import ExitIcon from '@/assets/icons/exit-icon.svg?component';
import BackIcon from '@/assets/icons/back-icon.svg?component';

const image = ref('');
const nickName = ref('');
const route = useRoute();
const router = useRouter();
const pageInBackend = ref(false);
const path = ref('');
const emit = defineEmits(['handleExit']);
// defineProps({
//   options: {
//     type: Array<any>,
//     default: () => [],
//   },
// });

const options = ref([]);

const handleChangePage = () => {
  if (route.path.indexOf('/manage') > -1) {
    pageInBackend.value = !pageInBackend.value;
    router.push(path.value || localStorage.getItem('path'));
  } else {
    path.value = route.path;
    localStorage.setItem('path', path.value);
    router.push('/manage/details');
    pageInBackend.value = !pageInBackend.value;
  }
};

watch(
  () => route.path,
  (val) => {
    if (val.indexOf('/manage') > -1) {
      options.value = [
        {
          content: '返回系统',
          icon: BackIcon,
          onClick: () => {
            handleChangePage();
          },
        },
        {
          content: '退出登录',
          icon: ExitIcon,
          onClick: () => {
            console.log('退出系统');
            emit('handleExit');
          },
        },
      ];
    } else {
      options.value = [
        {
          content: '管理后台',
          icon: SettingIcon,
          onClick: () => {
            handleChangePage();
          },
        },
        {
          content: '退出登录',
          icon: ExitIcon,
          onClick: () => {
            console.log('退出系统');
            emit('handleExit');
          },
        },
      ];
    }
  },
);

// const ComponentArr = shallowRef(props.options);

onMounted(() => {
  if (route.path.indexOf('/manage') > -1) {
    options.value = [
      {
        content: '返回系统',
        icon: BackIcon,
        onClick: () => {
          handleChangePage();
        },
      },
      {
        content: '退出登录',
        icon: ExitIcon,
        onClick: () => {
          console.log('退出系统');
          emit('handleExit');
        },
      },
    ];
  } else {
    options.value = [
      {
        content: '管理后台',
        icon: SettingIcon,
        onClick: () => {
          handleChangePage();
        },
      },
      {
        content: '退出登录',
        icon: ExitIcon,
        onClick: () => {
          console.log('退出系统');
          emit('handleExit');
        },
      },
    ];
  }
  const loginType = Number(Cookies.get('login_type'));

  // const loginTypeTbj = {
  //   1: '手机号登录',
  //   2: '微信登陆',
  //   3: '公众号登录(不支持)',
  //   4: 'QQ登录',
  // };
  switch (loginType) {
    case 1:
      Cookies.set('nick_name', '');
      Cookies.set('head_img', '');
      nickName.value = '';
      break;
    case 2:
    case 4:
      nickName.value = decodeURI(Cookies.get('nick_name'));
      image.value = unescape(Cookies.get('head_img'));
      break;
    case 3:
      Cookies.set('nick_name', '');
      Cookies.set('head_img', '');
      nickName.value = '';
      break;
    default:
      // todo 未登录
      console.log('用户未登录');
  }
});
</script>
<style lang="less" scoped>
@import '@/style/variables';

.login-container {
  cursor: pointer;

  .user-menu {
    color: #fff;

    .nickname {
      margin: 0 4px 0 10px;
    }
  }
}
.menu-icon {
  display: flex;
  width: 75px;
  justify-content: space-between;
  align-items: center;
}
</style>
