import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "12",
  height: "11",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "m2.207 3 1.647-1.646-.707-.708-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5.707-.708L2.207 4H8a3 3 0 1 1 0 6H3v1h5a4 4 0 1 0 0-8H2.207Z",
      fill: "#0F1829"
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }