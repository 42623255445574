import md5 from 'js-md5';

// 防止接口重复攻击
function randomWord(randomFlag: boolean, min: number, max: number) {
  // * randomWord 产生任意长度随机字母数字组合
  // * randomFlag-是否任意长度 min-任意长度最小位[固定位数] max-任意长度最大位
  let str = '';
  let range = min;
  const arr = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  // 随机产生
  if (randomFlag) {
    range = Math.round(Math.random() * (max - min)) + min;
  }
  for (let i = 0; i < range; i++) {
    const pos = Math.round(Math.random() * (arr.length - 1));
    str += arr[pos];
  }
  return str;
}

function getTimestamp() {
  // 获取时间戳
  const date = `${new Date()}`;
  const timestamp = String(Date.parse(date) / 1000);
  return timestamp;
}

export default function paramsEncryption(data: any) {
  console.log(data);
  const noncestr = `plaza${randomWord(true, 8, 10)}`;
  const timestamp = getTimestamp();
  // const requestData = JSON.parse(JSON.stringify(data));
  const password = 'MALLSERVER888LUCASYYE';
  const str = password + noncestr + timestamp;
  // if (Object.keys(requestData).length > 0) {
  //   str = str + JSON.stringify(requestData);
  // }
  const sign = md5(str).toString();
  return {
    sign,
    timestamp,
    noncestr,
  };
}
