import Layout from '@/layouts';
import MenuSearIcon from '@/assets/icons/menu-search-icon.svg';

export default [
  {
    path: '/search',
    component: Layout,
    redirect: '/search/mall',
    name: 'search',
    meta: {
      title: '商场筛选',
      requireAuth: true,
    },
    children: [
      {
        path: 'mall',
        name: 'SearchMall',
        component: () => import('@/pages/search/index.vue'),
        meta: {
          title: '商场筛选',
          icon: MenuSearIcon,
        },
      },
    ],
  },
];
