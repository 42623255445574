<template>
  <div v-if="!isMallSearch" class="top-menu-container">
    <div class="title">{{ title }}</div>
    <t-form class="top-form">
      <div class="top-form-insight">
        <!-- 商场选择组件 -->
        <t-form-item name="mall" style="flex: 0; width: 320px; margin-right: 48px">
          <t-cascader
            v-model="selectedValue"
            filterable
            style="width: 320px"
            placeholder="请选择商场"
            :options="mallsOptions"
            :suffix-icon="renderIcon"
            :loading="selectedMallLoading"
            @change="handleChangeSelect"
          >
          </t-cascader>
        </t-form-item>
        <t-form-item name="month" style="width: 240px">
          <t-date-picker
            v-if="isShowMallDate"
            v-model="selectedMonthVal"
            class="date-picker"
            style="width: 240px"
            :mode="timeSelectModal"
            :disable-date="disableDate"
            :suffix-icon="renderDateSuffixIcon"
            @change="handleChangeDate"
          >
          </t-date-picker>
        </t-form-item>
        <div class="select">
          <t-cascader
            v-if="isSource"
            :default-value="sourceType"
            :popup-props="{ overlayClassName: 'select-type' }"
            style="width: 240px"
            :suffix-icon="renderIcon"
            :options="[
              { label: '居住地', value: 'H' },
              { label: '工作地', value: 'W' },
            ]"
            @change="handleChangeType"
          ></t-cascader>
        </div>
        <t-button v-if="isShowDownExcel && hasExport" :disabled="!hasData" class="top-form-btn" @click="downData"
          >导出</t-button
        >
      </div>
    </t-form>
  </div>
  <!-- 商场优选部分 -->
  <!-- <div v-else class="mall-search-container">
    <t-cascader
      v-model="selectedCityVal"
      filterable
      style="width: 320px"
      size="large"
      placeholder="请选择城市"
      :options="regionsOptions"
      :loading="selectedCityLoading"
    />
  </div> -->
</template>
<script setup lang="ts">
/* eslint-disable camelcase */
import { onMounted, watch, ref } from 'vue';
import dayjs from 'dayjs';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { renderIcon, renderDateSuffixIcon } from './index';
import request from '@/utils/request';
import { ResMallListType } from '@/interface';
import { useMenuStore, useCityStore, getPermissionStore, useUserStore } from '@/store';
// import { REGIONS } from '@/config/regions';
import router from '@/router';
// import { MALL_LIST } from '@/config/mallList';
defineProps({
  hasData: {
    type: Boolean,
  },
});

const route = useRoute();
const menuStore = useMenuStore();
const cityStore = useCityStore();
const userStore = useUserStore();
const permissionStore = getPermissionStore();
const title = ref('');
const isShowCity = ref(route.path.indexOf('/city') > -1);
const isPeople = ref(route.path.indexOf('/population') > -1);
const timeSelectModal = ref(localStorage.getItem('timeValue') || 'month');
const emit = defineEmits(['downData', 'downData2']);
const isShowDownExcel = ref(false);
const sourceType = ref(localStorage.getItem('sourceType') || 'W');
const isMallSearch = ref(route.path.indexOf('/search') > -1);
const selectedValue = ref('');

// 城市选择
// const cityRef = ref(null);
const selectedCityVal = ref('');
const regionsOptions = ref([]);
const selectedCityLoading = ref(false);

// 商场选择
// const mallRef = ref(null);
const mallsOptions = ref([]);
const mallsObj = ref({});
const selectedMallLoading = ref(false);
// 月份选择
const selectedMonthVal = ref(localStorage.getItem('selectedMonth'));
// 禁用时间选择
const disableDate = ref({
  before: dayjs('2020-01-01').format(),
  after: dayjs().startOf('month').subtract(1, 'month').format(),
});
const hasExport = ref(false);
const isSource = ref(false);
const isShowMallDate = ref(true);

/**
 * 设置禁用月份
 */
const setDisabelMonth = (adcode: string) => {
  const { midsInfo } = storeToRefs(cityStore);

  midsInfo.value.forEach((item) => {
    if (item.adcode === adcode) {
      const lastMonth = dayjs().startOf('month').subtract(1, 'month');
      let afterTime = dayjs(item.end_date);
      let beforeTime = dayjs(item.start_date);
      const sourceBeforeData = dayjs('2022-10-1');
      if (route.path.indexOf('/source') > -1 && dayjs(item.start_date).isBefore(sourceBeforeData)) {
        beforeTime = sourceBeforeData;
      }
      const currentMonth = dayjs(selectedMonthVal.value);

      if (lastMonth.isBefore(afterTime)) {
        afterTime = lastMonth;
      }

      disableDate.value = {
        before: beforeTime.format(),
        after: afterTime.format(),
      };

      if (!selectedMonthVal.value || currentMonth.isBefore(beforeTime) || currentMonth.isAfter(afterTime)) {
        let time = '';
        if (dayjs().format('DD') === '01') {
          time = afterTime.subtract(1, 'month').format('YYYY-MM-DD');
        } else {
          time = afterTime.format('YYYY-MM-DD');
        }
        selectedMonthVal.value = time;
        menuStore.setSelectedMonth(time);
        localStorage.setItem('selectedMonth', time);
      }
    }
  });
};

/**
 * 获取 mall 列表
 * @param tel
 */
const fetchMallList = async (tel) => {
  selectedCityLoading.value = true;
  selectedMallLoading.value = true;
  const timeValue = Math.floor(new Date().getTime() / 1000);
  try {
    const res: ResMallListType = await request.get('/mall/list', {
      params: {
        tel,
        timeValue,
      },
    });
    if (res.code === 0) {
      const mallObj = {};
      const tmpObj = {};
      const regionsObj = await request.get('/mall/get-ad-list');
      const regionsObjResult = [];
      const provinceMap = new Map(); // 用于缓存省份信息
      for (const item of regionsObj.data) {
        const [province, city] = item.full_name.split(',');
        if (item.level === '2,3') {
          if (item.adcode === '810000' || item.adcode === '820000') {
            continue;
          }
          // 处理省份信息
          const provinceItem = {
            label: province,
            value: province,
            children: [
              {
                label: city,
                value: `${province}.${item.adcode}`,
              },
            ],
          };
          // 将省份信息缓存到Map中
          provinceMap.set(item.adcode, provinceItem);
        } else {
          // 处理城市信息
          const provinceItem = provinceMap.get(`${item.adcode.substr(0, 2)}0000`);
          if (!provinceItem) {
            const provinceItem = {
              label: province,
              value: province,
              children: [
                {
                  label: city,
                  value: `${province}.${item.adcode}`,
                },
              ],
            };
            provinceMap.set(`${item.adcode.substr(0, 2)}0000`, provinceItem);
          }
          if (provinceItem) {
            provinceItem.children.push({
              label: city,
              value: `${province}.${item.adcode}`,
            });
          }
        }
      }
      for (const [index, value] of provinceMap) {
        regionsObjResult.push(value);
      }
      console.log('regionsObjResult---->', regionsObjResult);
      // 格式转化以城市id为key
      res.data.forEach((item) => {
        const { province, city, mall_id } = item;

        if (!mallObj[province]) {
          mallObj[province] = {};
        }
        if (!mallObj[province][city]) {
          mallObj[province][city] = {};
        }

        mallObj[province][city][mall_id] = item;
        tmpObj[mall_id] = item;
      });
      mallsObj.value = tmpObj;

      // 商场按区域分类
      for (const province in mallObj) {
        const cityItem = mallObj[province];
        const provinceObj: any = {
          label: province,
          value: province,
          children: [],
        };
        for (const city in cityItem) {
          const mallItem = cityItem[city];
          const cityObj: any = {
            label: city,
            children: [],
          };

          for (const mall_id in mallItem) {
            const malls = mallItem[mall_id];
            const mallObj = {
              label: malls.name,
              value: `${province}.${malls.adcode}.${malls.mall_id}`,
            };

            cityObj.value = `${province}.${malls.adcode}`;
            cityObj.children.push(mallObj);
          }

          regionsObjResult.map((p) => {
            const newP = p;
            if (newP.label === province) {
              newP.children.map((c) => {
                const newC: any = c;

                if (newC.label === city) {
                  newC.children = cityObj.children;
                }
                return newC;
              });
            }

            return newP;
          });
          provinceObj.children.push(cityObj);
        }
      }

      const tmpRegion = [];
      const tmpRegion2 = [];
      // 初始化城市选项
      regionsObjResult.forEach((province) => {
        const p = {
          label: province.label,
          value: province.value,
          children: [],
        };
        const p2 = {
          label: province.label,
          value: province.value,
          children: [],
        };
        if (province.children && province.children.length > 0) {
          province.children.forEach((city) => {
            const c = {
              label: city.label,
              value: city.value,
              location: city.location,
            };
            const c2 = city;
            if (city.children && city.children.length > 0) {
              p.children.push(c);
              p2.children.push(c2);
            }
          });
        }
        if (p.children.length > 0) {
          tmpRegion.push(p);
          tmpRegion2.push(p2);
        }
      });
      regionsOptions.value = tmpRegion;
      userStore.setCityList(regionsOptions.value);
      selectedCityLoading.value = false;

      return tmpRegion2;
    }
  } catch (e) {
    console.log(e);
  }
  return [];
};

// 设置默认商场
const setDefaultMall = (adcode?: any) => {
  if (adcode) {
    const { adcode: rankAdcode } = route.query;
    if (rankAdcode) {
      adcode = rankAdcode;
    }
    mallsOptions.value.forEach((p) => {
      p.children.forEach((c) => {
        const code = c.value.split('.')[1];
        if (adcode === code) {
          const { mall_id: mallId } = route.query;
          if (mallId) {
            c.children.forEach((item) => {
              if (item.value.split('.')[2] === mallId) {
                selectedValue.value = item.value;
                menuStore.setSelectedMall(mallsObj.value[Number(mallId)]);
              }
            });
          } else {
            const firstMall = c.children[0];
            const id = firstMall.value.split('.')[2];

            selectedValue.value = firstMall.value;
            menuStore.setSelectedMall(mallsObj.value[id]);
          }
        }
      });
    });
  } else {
    const firstMall = mallsOptions.value[0].children[0].children[0];
    const [c, a, i] = firstMall.value.split('.');

    selectedValue.value = firstMall.value;
    menuStore.setSelectedProvince(c);
    menuStore.setSelectedCityAdcode(a);
    menuStore.setSelectedMall(mallsObj.value[i]);
    setDisabelMonth(a);
  }
};

// 设置菜单的选项
const setMenuOptions = () => {
  let hasDeafultCityPermission = false;
  let province = localStorage.getItem('selectedProvince');
  let adcode = localStorage.getItem('selectedCityAdcode');
  let cityName = localStorage.getItem('cityName');
  // const { selectedMall } = storeToRefs(menuStore);
  let center = localStorage.getItem('selectedCityCenter');
  regionsOptions.value.forEach((item: any) => {
    if (item.value === province) {
      hasDeafultCityPermission = true;
    }
  });
  let localStorageMall;
  if (isShowCity.value) {
    // 城市组件初始化
    if (!province || !adcode || !center || !cityName || !hasDeafultCityPermission) {
      const firstValue = regionsOptions.value[0].children[0];
      const [c, a] = firstValue.value.split('.');
      cityName = firstValue.label;
      province = c;
      adcode = a;
      center = firstValue.location;
      // const { lat, lng } = center;
      // menuStore.setselectedCityCenter({ lat, lng });
    }    
    menuStore.setSelectedProvince(province);
    menuStore.setSelectedCityAdcode(adcode);
    localStorage.setItem('cityName', cityName);
    setDisabelMonth(adcode);
  }
  // 商场组件选项初始化
  const { mall_id: mallId } = route.query;

  try {
    localStorageMall = JSON.parse(localStorage.getItem('selectedMall'));
  } catch (err) {
    console.log('Error: ', err.message);
  }
  if (localStorageMall && !mallId && hasDeafultCityPermission) {
    const { province: provinceLocal, adcode: adcodeLocal, mall_id: mallId } = localStorageMall;
    if (String(adcode) === String(adcodeLocal)) {
      selectedValue.value = `${provinceLocal}.${adcode}.${mallId}`;
      menuStore.setSelectedMall(localStorageMall);
      setDisabelMonth(adcode);
    } else {
      setDefaultMall(adcode);
    }
  } else {
    setDefaultMall(adcode);
    const { selectedMall } = storeToRefs(menuStore);
    const center = selectedMall.value.center_gcj;
    const [lat, lng] = center.split(',');
    menuStore.setselectedCityCenter({ lat, lng });
  }
};

const downData = () => {
  if (route.path.indexOf('/portrait') > -1) {
    const selectedMall = JSON.parse(localStorage.getItem('selectedMall'));
    emit('downData2', { selectedMall, selectedMonthVal });
  } else {
    emit('downData', { selectedMonthVal, selectedCityVal });
  }
};

const initMall = async () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const tel = String(user.tel);
  const mallRes = await fetchMallList(tel);
  mallsOptions.value = mallRes;
  selectedMallLoading.value = false;

  if (mallRes && mallRes.length > 0) {
    setMenuOptions();
  }
};

/**
 * 商场选择
 * @param val
 */
const handleChangeMall = (val) => {
  if (val) {
    const [province, adcode, mallId] = val.split('.');
    const mall = mallsObj.value[mallId];
    const selectMallCenter = mall.center_gcj;
    const [lat, lng] = selectMallCenter.split(',');
    let cityName = '';
    mallsOptions.value.forEach((p) => {
      p.children.forEach((c) => {
        const code = c.value.split('.')[1];
        if (code === adcode) {
          cityName = c.label;
        }
      });
    });
    // 清空 query
    router.push({ query: {} });
    menuStore.setSelectedProvince(province);
    menuStore.setSelectedCityAdcode(adcode);
    menuStore.setselectedCityCenter({ lat, lng });
    localStorage.setItem('cityName', cityName);
    menuStore.setSelectedMall(mall);
    setDisabelMonth(adcode);
  }
};

/**
 * 选择更改
 * @param val
 */
const handleChangeSelect = (val: string) => {
  handleChangeMall(val);
};

/**
 * 日期选择
 * @param val 月份
 */
const handleChangeDate = (val, { dayjsValue }) => {
  // 格式化时间
  if (val && timeSelectModal.value === 'month') {
    menuStore.setSelectedMonth(`${val}-01`);
  } else if (val && timeSelectModal.value === 'week') {
    let value: any = dayjsValue.format('YYYY-MM-DD');
    value = dayjs(value).add(1, 'day').format('YYYY-MM-DD');
    menuStore.setSelectedMonth(value);
  } else {
    menuStore.setSelectedMonth(val);
  }
};

const handleChangeType = (val) => {
  menuStore.setSourceType(val);
  // localStorage.setItem('sourceType', val);
};
// 监听路由
watch(
  () => route.path,
  (val) => {
    title.value = route.meta.title as string;
    if (val.indexOf('/insight') > -1) {
      isShowCity.value = false;
    }
    if (val.indexOf('/business') > -1) {
      isShowMallDate.value = false;
    } else {
      isShowMallDate.value = true;
    }
    if (
      val.indexOf('/city') > -1 ||
      route.path.indexOf('/portrait') > -1 ||
      route.path.indexOf('/flow') > -1 ||
      route.path.indexOf('/source') > -1
    ) {
      isShowDownExcel.value = true;
    } else {
      isShowDownExcel.value = false;
    }
    if (route.path.indexOf('/source') > -1) {
      isSource.value = true;
      sourceType.value = localStorage.getItem('sourceType') || 'W';
    } else {
      isSource.value = false;
    }
    if (val.indexOf('/city') > -1) {
      isShowCity.value = true;
    }
    if (val.indexOf('/population') > -1) {
      isPeople.value = true;
      timeSelectModal.value = 'month';
      // localStorage.setItem('timeType', 'xm');
    } else {
      timeSelectModal.value = 'month';
      isPeople.value = false;
    }
    if (val.indexOf('/search') > -1) {
      isMallSearch.value = true;
    } else {
      isMallSearch.value = false;
    }
    setMenuOptions();
  },
);

onMounted(async () => {
  const hasExcelArr = ['/city', '/portrait', '/flow', '/source'];
  hasExcelArr.forEach((item: string) => {
    if (route.path.indexOf(item) > -1) {
      isShowDownExcel.value = true;
    }
  });
  if (route.path.indexOf('/business') > -1) {
    isShowMallDate.value = false;
  }
  if (route.path.indexOf('/source') > -1) {
    isSource.value = true;
  }
  const { exportBtn } = permissionStore;
  hasExport.value = exportBtn;
  title.value = route.meta.title as string;
  if (mallsOptions.value.length === 0) {
    // 初始化城市、商场、月份选择项
    initMall();
  }
});
</script>

<style lang="less" scoped>
@import '@/style/variables';

.top-form {
  &-btn {
    position: absolute;
    right: 20px;
  }
  &-insight {
    display: flex;
  }
}
.top-menu-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 132px;
  padding: 24px;
  background-color: #fff;
  z-index: 9999;
}

.mall-search-container {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 24px;
  z-index: 9999;
}
.search-input {
  display: flex;
  justify-content: space-between;
  width: 320px;
}

.search-mall {
  &-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    width: 274px;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 1px 10px rgba(15, 24, 41, 0.03), 0px 4px 5px rgba(15, 24, 41, 0.06),
      0px 2px 4px -1px rgba(15, 24, 41, 0.06);
  }
  &-text {
    line-height: 24px;
  }
  &-icon {
    display: block;
    width: 7px;
    height: 7px;
    border-top: 1px solid rgba(15, 24, 41, 0.5);
    border-left: 1px solid rgba(15, 24, 41, 0.5);
    transform: rotate(45deg);
  }
  &-active {
    transform: rotate(225deg);
  }
  &-content {
    margin-top: 8px;
    padding: 24px;
    width: 274px;
    background-color: #ffffff;
    border-radius: 4px;
    &-title {
      display: block;
      line-height: 22px;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    &-input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
    }
    &-btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
      > span {
        padding: 4px 29px;
        line-height: 22px;
        border-radius: 5px;
        border: 1px solid #d0d5dc;
        font-size: 14px;
        font-weight: 500;
        &:last-child {
          background: #0062ff;
          color: #ffffff;
          border: 1px solid #0062ff;
          margin-left: 8px;
        }
      }
    }
  }
}
.title {
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #0f1829;
}

.select {
  margin-left: 50px;
}
.top-form-btn {
  background: #0062ff;
  border-radius: 4px;
}
</style>

<style lang="less">
.t-form__controls {
  margin-left: 0 !important;
}
.t-date-picker--month {
  padding: 0 16px;
}
.t-date-picker__panel td:first-child .t-date-picker__cell-wrapper {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.t-date-picker__panel td:last-child .t-date-picker__cell-wrapper {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.t-date-picker__cell--active-start .t-date-picker__cell-wrapper {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.t-date-picker__cell--active-end .t-date-picker__cell-wrapper,
.t-date-picker__cell--last-day-of-month .t-date-picker__cell-wrapper {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.t-date-picker__cell--first-day-of-month .t-date-picker__cell-wrapper {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.t-date-picker__cell--disabled .t-date-picker__cell-wrapper {
  cursor: not-allowed;
  border-radius: 20px;
  background-color: var(--td-bg-color-component-disabled);
  border-color: var(--td-bg-color-component-disabled);
}
.select-type {
  width: 240px;
  .narrow-scrollbar {
    width: 240px;
  }
}
</style>
