import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "14",
  height: "14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M6.5 0v7h1V0h-1Z",
      fill: "#0F1829"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      d: "M.5 7.5A6.5 6.5 0 0 1 3.968 1.75l.5.867a5.5 5.5 0 1 0 5.062 0l.502-.867A6.5 6.5 0 1 1 .5 7.5Z",
      fill: "#0F1829"
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }