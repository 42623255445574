<template>
  <div class="main-container">
    <TopMenu v-if="isShow" :has-data="hasData" @down-data="downData" @down-data2="downData2" />
    <router-view v-if="!isRefreshing" v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <keep-alive :include="aliveViews">
          <component :is="Component" @on-mall-data="onMallData" />
        </keep-alive>
      </transition>
    </router-view>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { computed, ComputedRef, onMounted, ref, watch } from 'vue';
import { isArray } from 'lodash';
import { useTabsRouterStore } from '@/store';
import TopMenu from '@/components/TopMenu/index.vue';
import { downExcel, downloadXlsx } from '@/utils/downExcel';

const route = useRoute();
const isShow = ref(true);
const hasData = ref(false);
let mallData: any;
let fileNames = '';
let excelType = 1;
let sheetNames: any = '';
const aliveViews = computed(() => {
  const tabsRouterStore = useTabsRouterStore();
  const { tabRouters } = tabsRouterStore;
  return tabRouters.filter((route) => route.isAlive && route.path.indexOf('insight') === -1).map((route) => route.name);
}) as ComputedRef<string[]>;

const isRefreshing = computed(() => {
  const tabsRouterStore = useTabsRouterStore();
  const { refreshing } = tabsRouterStore;
  return refreshing;
});
const onMallData = (val: Array<any>, fileName = '', sheetName = false, type = 1) => {
  if (val?.length) {
    hasData.value = true;
  } else {
    hasData.value = false;
  }
  if (!isArray(val)) {
    hasData.value = true;
  }
  if (route.path.indexOf('/local') > -1 || route.path.indexOf('/foreign') > -1) {
    mallData = val.map((item) => ({
      ...item,
      value: `${item.value}%`,
    }));
  } else {
    mallData = val;
  }
  fileNames = fileName;
  excelType = type;
  sheetNames = sheetName;
};

// 商场地图数据导出 || 单sheet导出
const downData = ({ selectedMonthVal }) => {
  const routeTitleMap = new Map([
    ['rank', '客流人气排名'],
    ['male', '男性客群热度'],
    ['female', '女性客群热度'],
    ['age', '各年龄段客群热度'],
    ['group', '各族群客群热度'],
    ['consume', '各消费属性客群'],
  ]);
  if (excelType === 1) {
    const selectedProvince = localStorage.getItem('selectedProvince');
    const cityName = localStorage.getItem('cityName');
    const city = `城市: ${selectedProvince}/${cityName}`;
    let dataItem;
    if (route.path.indexOf('/source') > -1) {
      const typeName = localStorage.getItem('sourceType') === 'H' ? '居住地排名' : '工作地排名';
      dataItem = `商场洞察-${route.meta.title}-${typeName}`;
    } else {
      const title = routeTitleMap.get(route.path.split('/')[2]);
      dataItem = `商场地图-${title}-${route.meta.title}`;
    }
    const selectTime = `${selectedMonthVal.value.split('-')[0]}${selectedMonthVal.value.split('-')[1]}`;
    const month = `日期: ${selectTime}`;
    const text = `${cityName}_${selectTime}_${route.meta.title}`;
    const rankTitle = localStorage.getItem('rankTitle');
    const title = ['排名', '区域名称', rankTitle];
    if (route.meta.type === '1') {
      title[2] = '月度累积客流人数';
      title.push('日均客流人数');
    } else if (route.meta.type === '2') {
      title[2] = '月度累积客流人次';
      title.push('日均客流人次');
    }
    const head = [[city], [dataItem], [month], title];
    downExcel(head, mallData, text);
  } else {
    downloadXlsx(mallData, fileNames, sheetNames);
  }
};

// 商场洞察数据导出 || 多Sheet导出
const downData2 = ({ selectedMall, selectedMonthVal }) => {
  const city = `区域:${selectedMall.province}${selectedMall.city}${selectedMall.name}`;
  const dataTitle = `数据项:商场洞察-${route.meta.title}`;
  const time = `${selectedMonthVal.value.split('-')[0]}${selectedMonthVal.value.split('-')[1]}`;
  const dataListItem = [[city], [dataTitle], [time]];
  const dataList = [];
  let item = [];
  const dataCreate = (key: string, title: Array<any>) => {
    item = [];
    item.push(...dataListItem);
    item.push(title);
    mallData[key].forEach((ele) => {
      item.push([ele.text, `${ele.percent}%`]);
    });
    dataList.push(item);
  };
  const itemKey = {
    101: ['性别', '占比'],
    102: ['年龄分布', '占比'],
    103: ['学历', '占比'],
    104: ['子女状态', '占比'],
    105: ['婚姻状态', '占比'],
    106: ['人生阶段', '占比'],
    107: ['消费水平', '占比'],
    108: ['商场到店频次', '占比'],
    109: ['餐饮消费频次', '占比'],
    110: ['餐饮消费水平', '占比'],
    111: ['居住社区房价', '占比'],
    112: ['是否有车', '占比'],
    114: ['到访4s店品牌', '占比'],
    115: ['手机品牌', '占比'],
    116: ['手机价格', '占比'],
    501: ['餐饮到访偏好', '占比'],
    502: ['购物到访偏好', '占比'],
    503: ['休闲娱乐到访偏好', '占比'],
    504: ['运动健身到访偏好', '占比'],
    505: ['汽车到访偏好', '占比'],
  };
  for (const key in mallData) {
    dataCreate(key, itemKey[key]);
  }
  const fileName = `${selectedMall.city}${selectedMall.name}_${time}_${route.meta.title}`;
  downloadXlsx(dataList, fileName, false, true);
};

watch(
  () => route.path,
  (val) => {
    if (val.indexOf('/manage') > -1) {
      isShow.value = false;
    } else {
      isShow.value = true;
    }
  },
);

onMounted(() => {
  const { path } = route;

  isShow.value = !(path.indexOf('/manage') > -1);
});
</script>
<style lang="less" scoped>
@import '@/style/variables';

.main-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 970px;
  transform: translate(0, 0);
}

.fade-leave-active,
.fade-enter-active {
  transition: opacity @anim-duration-slow @anim-time-fn-easing;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
