import { defineStore } from 'pinia';
import { TOKEN_NAME } from '@/config/global';
import { store } from '@/store';
// import { loginUser } from '@/services/loginApi';

const InitUserInfo = {
  roles: [],
  tel: '',
};

export const useUserStore = defineStore('user', {
  state: () => ({
    token: localStorage.getItem(TOKEN_NAME) || 'main_token', // 默认token不走权限
    userInfo: InitUserInfo,
    loginStatus: 0,
    roles: [],
    cityList: [],
  }),
  getters: {
    // roles: (state) => {
    //   return state.userInfo?.roles;
    // },
  },
  actions: {
    setCityList(cityList) {
      this.cityList = cityList;
    },
    async login(userInfo: Record<string, unknown>) {
      const mockLogin = async (userInfo: Record<string, unknown>) => {
        // 登录请求流程
        console.log(userInfo);
        // const { account, password } = userInfo;
        // if (account !== 'td') {
        //   return {
        //     code: 401,
        //     message: '账号不存在',
        //   };
        // }
        // if (['main_', 'dev_'].indexOf(password) === -1) {
        //   return {
        //     code: 401,
        //     message: '密码错误',
        //   };
        // }
        // const token = {
        //   main_: 'main_token',
        //   dev_: 'dev_token',
        // }[password];
        return {
          code: 200,
          message: '登陆成功',
          data: 'main_token',
          // data: 'mars',
        };
      };

      const res = await mockLogin(userInfo);
      if (res.code === 200) {
        this.token = res.data;
      } else {
        throw res;
      }
    },
    async getUserInfo() {
      const mockRemoteUserInfo = async (token: string) => {
        if (token === 'main_token') {
          return {
            name: 'marsliang',
            // roles: [
            //   'MapRank',
            //   'MapMale',
            //   'MapFemale',
            //   'MapAge',
            //   'MapGroup',
            //   'MapConsume',
            //   'InsightSurvey',
            //   'InsightFlow',
            //   'InsightPortrait',
            //   'InsightBusiness',
            // ],
            roles: ['all'],
          };
        }
        return {
          name: 'marsliang',
          roles: ['UserIndex', 'DashboardBase', 'login'],
        };
      };

      const res = await mockRemoteUserInfo(this.token);
      // const res = await loginUser();
      // console.log('get userinfo res: ', res);
      this.userInfo = res;
    },
    async logout() {
      localStorage.removeItem('user');
      this.token = '';
      this.userInfo = InitUserInfo;
    },
    async removeToken() {
      this.token = '';
    },
    async setLoginStatus(status: number) {
      this.loginStatus = status;
    },
    async initUserInfo() {
      try {
        const data = JSON.parse(localStorage.getItem('user'));
        this.userInfo = data;
      } catch (e) {
        console.log(e);
      }
    },
    async setUserInfo(userInfo: Record<string, unknown>) {
      this.userInfo = userInfo;
      localStorage.setItem('user', JSON.stringify(userInfo));
      // this.authrization = userInfo.authrization;
    },
    async setRole(role) {
      this.roles = role;
    },
  },
});

export function getUserStore() {
  return useUserStore(store);
}
