/* eslint-disable @typescript-eslint/naming-convention */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import mapRouters from './modules/map';
import insightRouters from './modules/insight';
import manageRouters from './modules/manage';
import searchRouters from './modules/search';
// import baseRouters from './modules/base';
// import componentsRouters from './modules/components';
// import othersRouters from './modules/others';

// 关于单层路由，meta 中设置 { single: true } 即可为单层路由，{ hidden: true } 即可在侧边栏隐藏该路由

// 存放动态路由
export const asyncRouterList: Array<RouteRecordRaw> = [
  ...mapRouters,
  ...insightRouters,
  ...manageRouters,
  ...searchRouters,
  // ...baseRouters,
  // ...componentsRouters,
  // ...othersRouters,
];

// 存放固定的路由
const defaultRouterList: Array<RouteRecordRaw> = [
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('@/pages/login/index.vue'),
  // },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/pages/home/index.vue'),
  },
  {
    path: '/',
    redirect: '/home',
    component: () => import('@/layouts/blank.vue'),
  },
  {
    path: '/:w+',
    name: '404Page',
    redirect: '/result/404',
  },
];

export const ALL_ROUTERS = [...defaultRouterList, ...asyncRouterList];

const router = createRouter({
  history: createWebHistory(),
  routes: ALL_ROUTERS,
  scrollBehavior() {
    return {
      el: '#app',
      top: 0,
      behavior: 'smooth',
    };
  },
});

export default router;
