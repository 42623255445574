import axios from 'axios';
import proxy from '../config/proxy';
import paramsEncryption from './redis';

const env = import.meta.env.MODE || 'test';
const host = env === 'mock' ? '/' : proxy[env].host; // 如果是mock模式 就不配置host 会走本地Mock拦截

const CODE = {
  LOGIN_TIMEOUT: 1000,
  REQUEST_SUCCESS: 0,
  REQUEST_FOBID: 1001,
};

const instance = axios.create({
  baseURL: host,
  timeout: 60000,
  withCredentials: true,
});

instance.interceptors.request.use((config) => {
  try {
    // 带授权信息
    const data = JSON.parse(localStorage.getItem('user')) || '';
    if (data.authorization) {
      config.headers.authorization = data.authorization;
    }
    const signs = paramsEncryption(data);
    config.headers.signs = JSON.stringify(signs);
  } catch (e) {
    // todo
  }
  return config;
});

// 延长超时时间，防止请求被cancel
instance.defaults.timeout = 150000;

// response 拦截器
instance.interceptors.response.use(
  (response) => {
    // 请求成功，直接返回
    if (response.status === 200) {
      const { data } = response;
      if (data.code === CODE.REQUEST_SUCCESS) {
        return data;
      }
    }
    return response;
  },
  (err) => {
    // 请求失败，统一的错误处理
    const { config } = err;

    if (!config || !config.retry) return Promise.reject(err);

    config.retryCount = config.retryCount || 0;

    if (config.retryCount >= config.retry) {
      return Promise.reject(err);
    }

    config.retryCount += 1;

    const backoff = new Promise((resolve) => {
      setTimeout(() => {
        resolve(null);
      }, config.retryDelay || 1);
    });

    return backoff.then(() => instance(config));
  },
);

export default instance;
