export default {
  development: {
    // 开发环境接口请求
    // host: './api',
    host: 'http://localhost:8082/api/v1',
    // 开发环境 cdn 路径
    cdn: '',
  },
  test: {
    // 测试环境接口地址
    // host: 'https:test-plaza.lbs.qq.com:8082/api/v1',
    host: 'https://test-plaza.lbs.qq.com/api/v1',
    // 测试环境 cdn 路径
    cdn: '',
  },
  release: {
    // 正式环境接口地址
    host: 'https://plaza.lbs.qq.com/api/v1',
    // 正式环境 cdn 路径
    cdn: '',
  },
};
