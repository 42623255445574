const domainName = {
  formal: 'plaza.lbs.qq.com',
  develop: 'test-plaza.lbs.qq.com',
  local: 'local.qq.com',
};

const { formal, develop, local } = domainName;

const domainObj = {
  [formal]: {
    url: 'https://lbsconsole.map.qq.com',
    // url: 'https://lbsconsole.sparta.html5.qq.com',
    domain: formal,
    local: 'formal',
    env: 'web_online',
  },
  [develop]: {
    url: 'https://lbsconsole.sparta.html5.qq.com',
    domain: develop,
    local: 'test',
    env: 'web_dev',
  },
  [local]: {
    url: 'https://lbsconsole.sparta.html5.qq.com',
    domain: develop,
    local: 'test',
    env: 'web_local',
  },
};

const hostName = window.location.hostname;
const domainConfig = domainObj[hostName];

export { domainConfig };
