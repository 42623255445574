import request from '@/utils/request';

export function loginUser() {
  let res;
  try {
    return request.get('/login/success');
  } catch (e) {
    console.log(e);
  } finally {
    // dataLoading.value = false;
  }
  return res;
}

export function getKeyInfo() {
  let res;
  try {
    return request.get('/login/keyinfo');
  } catch (e) {
    console.log(e);
  }
  return res;
}

// 添加登陆日志
export function addLoginLog(parmas) {
  let res;
  try {
    return request.post('/user/log', parmas);
  } catch (e) {
    console.log(e);
  }
  return res;
}

// 获取登陆日志
export function getLoginLog(beforeTime, afterTime) {
  let res;
  try {
    return request.get('/login/getLog', {
      params: {
        before_time: beforeTime,
        after_time: afterTime,
      },
    });
  } catch (e) {
    console.log(e);
  }
  return res;
}
