import * as XLSX from 'xlsx';

// 单sheet页导出
export const downloadXlsx = (dataList, fileName, sheetName = false, type = false) => {
  // 字符串转成字节流
  const stringToBuff = (str) => {
    const buf = new ArrayBuffer(str.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== str.length; ++i) {
      view[i] = str.charCodeAt(i);
    }
    return buf;
  };
  // 初始化Excel表格
  const workbook = XLSX.utils.book_new();
  if (type) {
    dataList.forEach((el: any) => {
      const worksheet = XLSX.utils.aoa_to_sheet(el);
      XLSX.utils.book_append_sheet(workbook, worksheet, el[3][0]);
    });
  } else {
    const worksheet = XLSX.utils.aoa_to_sheet(dataList);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName || fileName);
  }

  // 字节流转成二进制
  const xlsxBlob = new Blob(
    [stringToBuff(XLSX.write(workbook, { bookType: 'xlsx', bookSST: false, type: 'binary' }))],
    {
      type: '',
    },
  );

  const a = document.createElement('a');
  a.href = URL.createObjectURL(xlsxBlob);
  a.download = `${fileName}.xlsx`;
  a.click();
};

export const downExcel = (head: Array<any>, data: Array<any>, text: string) => {
  const dataList = [];
  head.forEach((item) => {
    dataList.push(item);
  });
  data.forEach((item, index) => {
    if (item.value_avg) {
      dataList.push([index + 1, item.name, item.value, item.value_avg]);
    } else {
      dataList.push([index + 1, item.name, item.value]);
    }
  });
  downloadXlsx(dataList, text);
};
