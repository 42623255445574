import { createApp } from 'vue';
import TDesign from 'tdesign-vue-next';
import 'tdesign-vue-next/es/style/index.css';
import mitt from 'mitt';
import dialogPlugin from '@/utils/dialogPlugin';

import { store } from './store';
import router from './router';
import '@/style/index.less';
import './permission';
import App from './App.vue';
import beaconAction from '@/utils/beaconAction';

// 创建并挂载根实例
const app = createApp(App);
app.config.globalProperties.$beaconAction = beaconAction;
// 第三方事务总线
app.config.globalProperties.$Bus = mitt();
app.config.globalProperties.$dialogPlugins = dialogPlugin;

app.use(TDesign);
app.use(store);
// 路由
app.use(router);

app.mount('#app');
