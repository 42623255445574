/* eslint-disable @typescript-eslint/quotes */
import { MessagePlugin, DialogPlugin } from 'tdesign-vue-next';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import { getPermissionStore, getUserStore, getCityStore } from '@/store';
import router from '@/router';
import { loginUser, addLoginLog } from '@/services/loginApi';
import request from '@/utils/request';

const permissionStore = getPermissionStore();
const userStore = getUserStore();
const cityStore = getCityStore();

NProgress.configure({ showSpinner: false });

const { whiteListRouters } = permissionStore;

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  const loginType = Cookies.get('login_type');
  console.log('loginType: ', loginType);

  const goHome = () => {
    const cookies = [
      'lbs_sid',
      'lbs_test_sid',
      'login_level',
      'head_img',
      'nick_name',
      'login_type',
      'sess_skey',
      'lbs_redirect',
      'lbs_refer',
    ];
    cookies.forEach((key) => {
      Cookies.remove(key, { domain: 'qq.com' });
    });
    userStore.logout();
    permissionStore.restore();
    if (to.path !== '/home') {
      router.push('/home');
    }
    next();
  };

  if (loginType) {
    try {
      const res = await loginUser();
      // 帐号停用
      if (res && res.data.code === 2) {
        const dislog = DialogPlugin({
          header: '商场通',
          body: '抱歉，该帐号已停止使用,请联系我们重新开通',
          theme: 'info',
          cancelBtn: null,
          onConfirm: () => {
            dislog.hide();
            goHome();
            localStorage.removeItem('firstLoginTime');
          },
        });
      }
      // 帐号企业Key过期
      if (res && res.data.code === 3) {
        const dislog = DialogPlugin({
          header: '温馨提示',
          body: '系统服务权限已到期，请联系商务开通权限使用。给您造成的不便敬请谅解。',
          theme: 'info',
          placement: 'center',
          destroyOnClose: true,
          cancelBtn: null,
          onConfirm: () => {
            dislog.hide();
            goHome();
            localStorage.removeItem('firstLoginTime');
          },
        });
      }
      if (res && res.isWeekEnd && to.name === 'home') {
        const dislog = DialogPlugin({
          header: '温馨提示',
          body: '系统权限即将到期，为避免影响您的使用请及时联系商务续约。给您造成的不便敬请谅解!',
          theme: 'info',
          placement: 'center',
          destroyOnClose: true,
          cancelBtn: null,
          onConfirm: () => {
            dislog.hide();
          },
        });
      }
      if (res && res.code === 0) {
        if (res.data) {
          const firstLoginTime = localStorage.getItem('firstLoginTime') || '';
          // 一天86400000毫秒
          if (firstLoginTime && dayjs().isAfter(firstLoginTime, 'day')) {
            const { userInfo } = res.data;
            const params = {
              user_id: userInfo.dataValues.user_id,
              user_name: userInfo.dataValues.user_name,
              date_time: dayjs().format('YYYY-MM-DD'),
              date_value: dayjs().format('YYYY-MM-DD HH:mm:ss'),
              services_id: userInfo.dataValues.services_id,
            };
            addLoginLog(params);
            localStorage.setItem('firstLoginTime', params.date_time);
          }
          const { userInfo, authorization } = res.data;
          const userId = userInfo.dataValues.user_id;
          const userType = res.data.userInfo.dataValues.privilege;
          const { midsInfo } = userInfo;
          console.log('城市周期：', midsInfo);
          userStore.setUserInfo({
            ...userInfo,
            tel: userInfo.tel,
            authorization,
          });
          // 设置城市周期
          cityStore.setMidsInfo(midsInfo);
          userStore.setLoginStatus(1);
          // 获取用户权限
          const userPeimission = await request.get('/user/get/permission', {
            params: {
              user_id: userId,
            },
          });
          // 根据返回数据对路由权限做动态添加。数据库字段应该设计和route-name一样，就不需要这么恶心的循环
          if (userPeimission.data) {
            const permissionArr = [];
            for (const key in userPeimission.data) {
              if (userPeimission.data[key]) {
                switch (key) {
                  case 'number':
                    permissionArr.push('MapRank');
                    break;
                  case 'male':
                    permissionArr.push('MapMale');
                    break;
                  case 'female':
                    permissionArr.push('MapFemale');
                    break;
                  case 'age':
                    permissionArr.push('MapAge');
                    break;
                  case 'group':
                    permissionArr.push('MapGroup');
                    break;
                  case 'consume':
                    permissionArr.push('MapConsume');
                    break;
                  case 'survey':
                    permissionArr.push('InsightSurvey');
                    break;
                  case 'flow':
                    permissionArr.push('InsightFlow');
                    break;
                  case 'portrait':
                    permissionArr.push('InsightPortrait');
                    break;
                  case 'business':
                    permissionArr.push('InsightBusiness');
                    break;
                  case 'mall':
                    permissionArr.push('SearchMall');
                    break;
                  case 'export':
                    permissionStore.setExportBtn(true);
                    break;
                  case 'source':
                    permissionArr.push('InsightSource');
                    break;
                  default:
                    continue;
                }
              }
            }
            // 后台权限控制
            if (userType === 1) {
              permissionArr.push('ManageDetails', 'ManageUser', 'ManageService', 'UserLog');
            } else {
              permissionArr.push('ManageDetails');
            }
            userStore.setRole(permissionArr);
          }
        }
      } else {
        goHome();
        localStorage.removeItem('firstLoginTime');
        return;
      }
    } catch (error) {
      console.error(error);
      goHome();
      localStorage.removeItem('firstLoginTime');
      return;
    }
  } else {
    goHome();
    localStorage.removeItem('firstLoginTime');
    return;
  }
  // 如果该路由需要鉴权，则验证用户的信息，如果不通过则跳转到登录页
  if (to.matched.some((matched) => matched.meta.requireAuth) && !userStore.userInfo) {
    console.log('requireAuth');
    next({
      name: 'home',
    });
  } else {
    const { token } = userStore;

    if (token) {
      // if (to.path === '/login') {登录
      //   userStore.logout();
      //   permissionStore.restore();
      //   next();
      //   return;
      // }
      try {
        // 获取用户信息
        await userStore.getUserInfo();
        const { roles } = userStore;
        // 控制路由显示
        await permissionStore.initRoutes(roles);
        if (router.hasRoute(to.name)) {
          next();
        } else {
          next(`/`);
        }
      } catch (error) {
        MessagePlugin.error(error);
        goHome();
        // next(`/home?redirect=${to.path}`);
        NProgress.done();
      }
    } else {
      /* white list router */
      if (whiteListRouters.indexOf(to.path) !== -1) {
        next();
      } else {
        goHome();
        // next(`/home?redirect=${to.path}`);
      }
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
