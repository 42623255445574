import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "12",
  height: "14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M10.667 4.238v5.523l-4.666 2.745-4.667-2.745V4.238l-.507-.862.507.862 4.667-2.745.507-.862L6 1.493l4.666 2.745ZM6.507.631a1 1 0 0 0-1.013 0L.827 3.376a1 1 0 0 0-.493.862v5.523a1 1 0 0 0 .493.862l4.667 2.745a1 1 0 0 0 1.014 0l4.666-2.745a1 1 0 0 0 .493-.862V4.238a1 1 0 0 0-.493-.862L6.508.631ZM7.578 7A1.57 1.57 0 0 1 6 8.564 1.57 1.57 0 0 1 4.425 7c0-.86.701-1.564 1.576-1.564A1.57 1.57 0 0 1 7.576 7ZM6 9.564A2.57 2.57 0 0 0 8.576 7a2.57 2.57 0 0 0-2.575-2.564A2.57 2.57 0 0 0 3.425 7 2.57 2.57 0 0 0 6 9.564Z",
      fill: "#000"
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }