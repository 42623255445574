import { defineStore } from 'pinia';
import { store } from '@/store';

// const midsInfo: {
//   id: number;
//   services_id: number;
//   user_id: number;
//   adcode: string;
//   start_date: string;
//   end_date: string;
// }[];

export const useCityStore = defineStore('mapRank', {
  state: () => ({
    selectedIndex: null,
    midsInfo: [],
  }),
  getters: {},
  actions: {
    setSelectedIndex(index: number) {
      this.selectedIndex = index;
    },
    setMidsInfo(mids: any) {
      this.midsInfo = mids;
    },
  },
});

export function getCityStore() {
  return useCityStore(store);
}
