import DialogPlugin from 'tdesign-vue-next/es/dialog/plugin';

export default ({ header, content, theme, cancelBtn, onConfirmHandler, onCloseHandler }) => {
  const confirmDia = DialogPlugin({
    header,
    body: content,
    theme,
    cancelBtn,
    onConfirm: () => {
      confirmDia.hide();
      confirmDia.destroy();
      onConfirmHandler();
    },
    onClose: () => {
      confirmDia.hide();
      confirmDia.destroy();
      onCloseHandler();
    },
  });
  return confirmDia;
};
