import Layout from '@/layouts';
import ShopFlowIcon from '@/assets/icons/assets-flow.svg';
import MaMerchanteIcon from '@/assets/icons/assets-merchant.svg';
import OverviewIcon from '@/assets/icons/assets-overview.svg';
import PortraitIcon from '@/assets/icons/assets-portrait.svg';
import SourceIcon from '@/assets/icons/assets-source.svg';

export default [
  {
    path: '/insight',
    component: Layout,
    redirect: '/insight/survey',
    name: 'insight',
    meta: {
      title: '商场洞察',
      requireAuth: true,
    },
    children: [
      {
        path: 'survey',
        name: 'InsightSurvey',
        component: () => import('@/pages/insight/survey/index.vue'),
        meta: {
          title: '商场概况',
          icon: OverviewIcon,
        },
      },
      {
        path: 'flow',
        name: 'InsightFlow',
        component: () => import('@/pages/insight/flow/index.vue'),
        meta: {
          title: '商场客流',
          icon: ShopFlowIcon,
        },
      },
      {
        path: 'source',
        name: 'InsightSource',
        component: () => import('@/pages/city/rank/index.vue'),
        meta: {
          title: '客流来源',
          rankTitle: '客流来源排名',
          icon: SourceIcon,
          type: '8',
          contentValue: '对商场游客进行职住地分析，统计输出TOP50居住地/工作地排名。',
        },
      },
      {
        path: 'portrait',
        name: 'InsightPortrait',
        component: () => import('@/pages/insight/portrait/index.vue'),
        meta: {
          title: '客流画像',
          icon: PortraitIcon,
        },
        children: [
          {
            path: 'natural',
            name: 'Natural',
            component: () => import('@/pages/insight/portrait/index.vue'),
            meta: {
              title: '自然属性',
              echarts: ['lifeStageContainer', 'maritalStatusContainer', 'childrenStatusContainer'],
            },
          },
          {
            path: 'economy',
            name: 'Economy',
            component: () => import('@/pages/insight/portrait/index.vue'),
            meta: {
              title: '财富属性',
            },
          },
          {
            path: 'arrival',
            name: 'Arrival',
            component: () => import('@/pages/insight/portrait/index.vue'),
            meta: {
              title: '到访偏好',
            },
          },
        ],
      },
      {
        path: 'business',
        name: 'InsightBusiness',
        component: () => import('@/pages/insight/business/index.vue'),
        meta: {
          title: '入驻商户',
          icon: MaMerchanteIcon,
        },
      },
    ],
  },
];
