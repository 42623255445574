import Layout from '@/layouts';

import FireIcon from '@/assets/icons/fire-icon.svg';
import MaleIcon from '@/assets/icons/male-icon.svg';
import FemaleIcon from '@/assets/icons/female-icon.svg';
import AgeIcon from '@/assets/icons/age-icon.svg';
import GroupIcon from '@/assets/icons/group-icon.svg';
import MoneyIcon from '@/assets/icons/money-icon.svg';

export default [
  {
    path: '/city',
    component: Layout,
    redirect: '/city/rank/number',
    name: 'map',
    meta: {
      title: '商场地图',
      requireAuth: true,
    },
    children: [
      {
        path: 'rank',
        name: 'MapRank',
        component: () => import('@/pages/city/rank/index.vue'),
        meta: {
          title: '客流人气排名',
          icon: FireIcon,
        },
        children: [
          {
            path: 'number',
            name: 'MapRankNumber',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '客流人数排名',
              data_type: '1',
              type: '1',
              title: '客流人数',
              contentValue: '按到访购物中心的人数进行排名',
            },
          },
          {
            path: 'peopleNumber',
            name: 'PeopleNumber',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '客流人次排名',
              data_type: '2',
              type: '2',
              title: '客流人次',
              contentValue: '按到访购物中心的人次进行排名',
            },
          },
          {
            path: 'local',
            name: 'MapRankLocal',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '本地客流占比',
              data_type: '0',
              type: '3',
              title: '本地客流',
              contentValue: '按到访购物中心客流中本地客流的占比进行排名',
            },
          },
          {
            path: 'foreign',
            name: 'MapRankForeign',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '外地客流占比',
              data_type: '0',
              type: '4',
              title: '外地客流',
              contentValue: '按到访购物中心客流中外地客流的占比进行排名',
            },
          },
          {
            path: 'radiation',
            name: 'MapRankRadiation',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '商圈辐射排名',
              data_type: '1',
              type: '5',
              title: '商圈辐射',
              contentValue: '按购物中心的商圈覆盖面积进行排名',
            },
          },
          {
            path: 'population',
            name: 'MapRankPopulation',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '商圈人口占比',
              data_type: '1',
              type: '7',
              title: '商圈人口',
              contentValue: '按购物中心的商圈人口数进行排名',
            },
          },
        ],
      },
      {
        path: 'male',
        name: 'MapMale',
        component: () => import('@/pages/city/rank/index.vue'),
        meta: { title: '男性客群热度', icon: MaleIcon },
        children: [
          {
            path: 'young',
            name: 'MapMaleYoung',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '青年男性最喜欢的购物中心',
              data_type: '0',
              type: '12',
              title: '青年男性',
              contentValue: '按到访购物中心客流中20-39岁男性客流的',
            },
          },
          {
            path: 'middle',
            name: 'MapMaleMiddle',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '中年男性最喜欢的购物中心',
              data_type: '0',
              type: '13',
              title: '中年男性',
              contentValue: '按到访购物中心客流中40-59岁男性客流的',
            },
          },
          {
            path: 'elder',
            name: 'MapMaleElder',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '老年男性最喜欢的购物中心',
              data_type: '0',
              type: '14',
              title: '老年男性',
              contentValue: '按到访购物中心客流中60岁及以上男性客流的',
            },
          },
        ],
      },
      {
        path: 'female',
        name: 'MapFemale',
        component: () => import('@/pages/city/rank/index.vue'),
        meta: { title: '女性客群热度', icon: FemaleIcon },
        children: [
          {
            path: 'young',
            name: 'MapFemaleYoung',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '青年女性最喜欢的购物中心',
              data_type: '0',
              type: '15',
              title: '青年女性',
              contentValue: '按到访购物中心客流中20-39岁女性客流的',
            },
          },
          {
            path: 'middle',
            name: 'MapFemaleMiddle',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '中年女性最喜欢的购物中心',
              data_type: '0',
              type: '16',
              title: '中年女性',
              contentValue: '按到访购物中心客流中40-59岁女性客流的',
            },
          },
          {
            path: 'elder',
            name: 'MapFemaleElder',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '老年女性最喜欢的购物中心',
              data_type: '0',
              type: '17',
              title: '老年女性',
              contentValue: '按到访购物中心客流中60岁及以上女性客流的',
            },
          },
        ],
      },
      {
        path: 'age',
        name: 'MapAge',
        component: () => import('@/pages/city/rank/index.vue'),
        meta: { title: '各年龄段客群热度', icon: AgeIcon },
        children: [
          {
            path: '00s',
            name: 'MapAge00s',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '00后最喜欢的购物中心',
              data_type: '0',
              type: '18',
              title: '00后',
              contentValue: '按到访购物中心客流中2000年以后出生客流的',
            },
          },
          {
            path: '90s',
            name: 'MapAge90s',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '90后最喜欢的购物中心',
              data_type: '0',
              type: '19',
              title: '90后',
              contentValue: '按到访购物中心客流中1990-1999年出生客流的',
            },
          },
          {
            path: '7080s',
            name: 'MapAge7080s',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '70后80后最喜欢的购物中心',
              data_type: '0',
              type: '20',
              title: '70后和80后',
              contentValue: '按到访购物中心客流中1970-1989年出生客流的',
            },
          },
        ],
      },
      {
        path: 'group',
        name: 'MapGroup',
        component: () => import('@/pages/city/rank/index.vue'),
        meta: { title: '各族群客群热度', icon: GroupIcon },
        children: [
          {
            path: 'elder',
            name: 'MapGroupElder',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '银发族最喜欢的购物中心',
              data_type: '0',
              type: '21',
              title: '银发族',
              contentValue: '按到访购物中心客流中60岁及以上客流的',
            },
          },
          {
            path: 'mothers',
            name: 'MapGroupMothers',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '准妈妈新妈妈最喜欢的购物中心',
              data_type: '0',
              type: '22',
              title: '准妈妈新妈妈',
              contentValue: '按到访购物中心客流中子女状态为“孕婴”客流的',
            },
          },
          {
            path: 'single',
            name: 'MapGroupSingle',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '单身族最喜欢的购物中心',
              data_type: '0',
              type: '23',
              title: '单身族',
              contentValue: '按到访购物中心客流中婚姻状态为“未婚”客流的',
            },
          },
          {
            path: 'student',
            name: 'MapGroupStudent',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '中学生最喜欢的购物中心',
              data_type: '0',
              type: '24',
              title: '中学生',
              contentValue: '按到访购物中心客流中学历为“初中/高中”客流的',
            },
          },
          {
            path: 'university',
            name: 'MapGroupUniversity',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '大学生最喜欢的购物中心',
              data_type: '0',
              type: '25',
              title: '大学生',
              contentValue: '按到访购物中心客流中学历为“大专/本科”客流的',
            },
          },
          {
            path: 'educated',
            name: 'MapGroupEducated',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '高学历人群最喜欢的购物中心',
              data_type: '0',
              type: '26',
              title: '高学历',
              contentValue: '按到访购物中心客流中学历为“硕士/博士及以上”客流的',
            },
          },
          {
            path: 'commuter',
            name: 'MapGroupCommuter',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '上班族最喜欢的购物中心',
              data_type: '0',
              type: '27',
              title: '上班族',
              contentValue: '按到访购物中心客流中人生阶段为“上班族”客流的',
            },
          },
        ],
      },
      {
        path: 'consume',
        name: 'MapConsume',
        component: () => import('@/pages/city/rank/index.vue'),
        meta: { title: '各消费属性客群', icon: MoneyIcon },
        children: [
          {
            path: 'high',
            name: 'MapConsumeHigh',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '高消费能力人群最喜欢的购物中心',
              data_type: '0',
              type: '28',
              title: '高消费',
              contentValue: '按到访购物中心客流中消费能力为“高”客流的',
            },
          },
          {
            path: 'middle',
            name: 'MapConsumeMiddle',
            component: () => import('@/pages/city/rank/index.vue'),
            meta: {
              rankTitle: '中消费能力人群最喜欢的购物中心',
              data_type: '0',
              type: '29',
              title: '中消费',
              contentValue: '按到访购物中心客流中消费能力为“中”客流的',
            },
          },
        ],
      },
    ],
  },
];
